@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
   url("./OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
   url("./OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* @font-face {
  font-family: "CircularStd-Medium";
  src: local("CircularStd-Medium"),
   url("./CircularStd-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CircularStd-Black";
  src: local("CircularStd-Black"),
   url("./CircularStd-Black.ttf") format("truetype");
  font-weight: bold;
} */